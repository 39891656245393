import React from "react";
import "./Games.scss";
import GameRow from "../../components/GamesRow/GamesRow";

const Games = () => {
    return (
		<>
			<div className="fywText">Games</div>
			<div className="gamesContainer">
				<GameRow />
			</div>
		</>
    );
}

export default Games;
