import React from "react";  
import './Projects.scss'; 

import Section1 from './sections/Section1/Section1'
import Section2 from './sections/Section2/Section2'
import Section3 from './sections/Section3/Section3'
import Section4 from './sections/Section4/Section4'
import Section5 from './sections/Section5/Section5'
import Section7 from './sections/Section7/Section7'
import Section8 from './sections/Section8/Section8'
import Section9 from './sections/Section9/Section9'
import ScrollIndicator from "../../components/ScrollIndicator/ScrollIndicator";

const Projects = () => {
    return ( 
        <div className="projectsContainer">  
            <ScrollIndicator />
            <div className="fywText">Projects</div>
                <div className="section section1" data-anchor="/projects#project1"><Section1 /></div>
                <div className="section section2" data-anchor="/projects#project9"><Section9 /></div>
                <div className="section section3" data-anchor="/projects#project8"><Section8 /></div>
                <div className="section section4" data-anchor="/projects#project2"><Section2 /></div>
                <div className="section section6" data-anchor="/projects#project4"><Section4 /></div>
                <div className="section section7" data-anchor="/projects#project5"><Section5 /></div>
                <div className="section section5" data-anchor="/projects#project3"><Section3 /></div>
                <div className="section section9" data-anchor="/projects#project7"><Section7 /></div>
        </div> 
    );
}

export default Projects;