import './ScrollIndicator.scss'
const ScrollIndicator = () => {
    return (
        <div className="scrollContainer">
            <div className="scroll-indicator">
                <span className="visually-hidden"></span>
                <span className="dot"></span>
            </div>
        </div>
    )
}

export default ScrollIndicator