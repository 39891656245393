import React from "react";

export const themes = {
  light: {
    background: "#e2e1e5",
  },
  dark: {
    background: "#36364c",
  },
};

export const ThemeContext = React.createContext();
