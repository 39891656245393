import React from "react";  
import './About.scss'; 
import uImg from '../../files/images/about/uoft.png'
import { Link } from "react-router-dom"

const About = () => {
    return (
        <div className="aboutContainer">  
            <div className="fywText">About</div>
            <div className="a_section"> 
                <div className="a_sectionLeft"> 

                    <Link to="/games"><div className="sectionTitle">Games</div></Link>
                    <div className="sectionParagraph">Assortment of games I have made. Special guests may appear as well specified with a "Made By".</div>
                    
                    <Link to="/projects"><div className="sectionTitle">Dev Projects </div></Link>
                    <div className="sectionParagraph">Assortment of development projects I created for a specific theme, personal projects for art or business and freelance projects.</div>

                    <Link to="/art"><div className="sectionTitle">Art Gallery</div></Link>
                    <div className="sectionParagraph">Assortment of art I have made. Paintings on canvas, drawings, halloween costumes and extra fun art projects.</div>
                    
                </div>
                <div className="a_sectionRight" data-aos="fade-down" data-aos-delay="200"></div>
            </div> 
            
            <div className="educationContainer">
                <div className="title">University of Toronto</div>
                <img src={uImg} alt="uImg"/>
            </div>
        </div>
    );
}

export default About;