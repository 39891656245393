import React, { useState, useEffect } from "react";
import ImageGallery from 'react-image-gallery';
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import "./image-gallery.css";
import './Art.scss';

const Art = () => {
    const [imageList, setImageList] = useState(null); 
    const [filter, setFilter] = useState(['paintings', 'drawings', 'costumes', 'extras']);
	const [isLoading, setIsLoading] = useState(true);

    const handleSetFilter = (category) =>
        filter.includes(category) ? setFilter(filter.filter(f=>f!==category)) : setFilter([...filter, category])

    useEffect(
        ()=> {
            fetch('https://onlysansone.ca/images/artList.json')
            .then((res) => {
                res.json().then(setImageList);
                setIsLoading(false);
            })
        }, []
    );

    const showGallery = () =>
        imageList?.filter(({category}) => filter.includes(category))
            ?.map(filteredData => (
                <ImageGallery
                    key={filteredData.name}
                    thumbnailPosition={"bottom"}
                    items={filteredData.images}
                    slideDuration={200}
                    showPlayButton={false}
                />)
            )
  
    return (
        <>
            <div className="fywText">Art</div>
            <div className="artContainer">
                <div className="filterContainer">
                    <div onClick={() => handleSetFilter('paintings')} className={classNames('filter f_paintings', !filter.includes('paintings') ? 'active':'')}>Paintings</div>
                    <div onClick={() => handleSetFilter('drawings')} className={classNames('filter f_drawings', !filter.includes('drawings') ? 'active':'')}>Drawings</div>
                    <div onClick={() => handleSetFilter('costumes')} className={classNames('filter f_costumes', !filter.includes('costumes') ? 'active':'')}>Costumes</div>
                    <div onClick={() => handleSetFilter('extras')} className={classNames('filter f_extras', !filter.includes('extras') ? 'active':'')}>Extras</div>
                </div> 

                {!isLoading ? showGallery() : <div className="isLoading"><LinearProgress /></div>}
                
            </div>
        </>
    );
}

export default Art;