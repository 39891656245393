import React from 'react'

const Section3 = () => ( 
    <div className="p_section">
        <div className="p_sectionLeft">
            <div className="divider"></div>
            <div className="title">Slab Digital</div>
            <div className="paragraph">A website designed for a marketing company. Express yo self.</div>
            <a href="https://sansonem-slabdigital.s3.ca-central-1.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer"><div className="button">Visit</div></a>
        </div>
        <div className="p_sectionRight p3"></div>
    </div>  
);

export default Section3;