import React from 'react' 

const Section9 = () => ( 
    <div className="p_section">
        <div className="p_sectionLeft">
            <div className="divider"></div>
            <div className="title">Rowdy Rooster Collective</div>
            <div className="paragraph">Professional NFT project. A community-driven NFT collection of over 8,888 finely curated, fully customizable Roosters cluckin’ 
            around on the Ethereum blockchain using the ERC-721 token standard. Each Rooster is programmatically generated 
            from hundreds of possible attributes which results in the creation of one kick ass character that you control and have the chance to bring to life.</div>
            <a href="https://rowdyrooster.io/" target="_blank" rel="noopener noreferrer"><div className="button">Visit</div></a>
        </div>
        <div className="p_sectionRight p9"></div>
    </div>  
);

export default Section9;