import React from 'react'  

const Section1 = () => (
    <div className="p_section">
        <div className="p_sectionLeft">
            <div className="divider"></div>
            <div className="title">Andrew Wiggins</div>
            <div className="paragraph">As a freelancer, I was given the opportunity to create and design the website for Andrew Wiggins, A famous basketball player.</div>
            <a href="https://www.andrew22wiggins.com/" target="_blank" rel="noopener noreferrer"><div className="button">Visit</div></a>
        </div>
        <div className="p_sectionRight p1"></div>
    </div>
);

export default Section1;