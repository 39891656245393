import React from 'react'

const Section7 = () => ( 
    <div className="p_section">
        <div className="p_sectionLeft">
            <div className="divider"></div>
            <div className="title">Silver Service Auto</div>
            <div className="paragraph">A+ mechanic shop. Oil change check.</div>
            <a href="http://silverserviceauto.ca/" target="_blank" rel="noopener noreferrer"><div className="button">Visit</div></a>
        </div>
        <div className="p_sectionRight p7"></div>
    </div>  
);

export default Section7;