import React, { useContext } from 'react';
import './GamesRow.scss';
import { ThemeContext } from '../Themes/ThemesContext';

function GameRow() {
    const theme = useContext(ThemeContext);

    return (
        <div className="gameRow">
            <div className="game newGame">
                <h1>Sone Racing</h1>
                <span className='made-by'>* NEW * - made with nextjs (Beta)</span>
                <div className="gameImage img7"></div>
                <a href="https://sone-racing.vercel.app/" target="_blank" rel="noopener noreferrer">
                    <div className="button gameButton">Play</div>
                </a>
            </div> 
            <div className="game" style={{"backgroundColor": theme.background}}>
                <h1>PaintDrop</h1>
                <div className="gameImage img1"></div>
                <a href="https://sansonem-paintdrop.s3.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer">
                    <div className="button gameButton">Play</div>
                </a>
            </div>
            <div className="game">
                <h1>PaintDrop 3d</h1>
                <span className='made-by'>Paint Drop 3d (Android Game)</span>
                <div className="gameImage img2"></div>
                <a href="https://onlysansone.ca/paintDrop61.apk" download rel="noopener noreferrer">
                    <div className="button gameButton">Download apk</div>
                </a>   
            </div>
            <div className="game">
                <h1>Scribole</h1>
                <span className='made-by'>Learn Italian (Android Game)</span>
                <div className="gameImage img6"></div>
                <a href="https://onlysansone.ca/scribole.apk" download rel="noopener noreferrer">
                    <div className="button gameButton">Download apk</div>
                </a>   
            </div>  
            <div className="game">
                <h1>WallGame</h1>
                <span className='made-by'>PC game only</span>
                <div className="gameImage img3"></div>
                <a href="https://sansonem-wallgame.s3.ca-central-1.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer">
                    <div className="button gameButton">Play</div>
                </a>    
            </div> 
            <div className="game" >
                <h1>Lost In Space</h1>
                <span className='made-by'>Made By: Cutch (PC game only)</span>
                <div className="gameImage img4"></div>
                <a href="https://sansonem-spacegame.s3.ca-central-1.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer">
                    <div className="button gameButton">Play</div>
                </a>    
            </div> 
            <div className="game" >
                <h1>Cricket</h1>
                <span className='made-by'>made for darts</span>
                <div className="gameImage img5"></div>
                <a href="https://sansonem-cricketgame.s3.ca-central-1.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer">
                    <div className="button gameButton">Play</div>
                </a>    
            </div>
        </div>
    );
}

export default GameRow;
